import React from "react";
import Page from "../containers/page";

const Volunteer = () => {

  const labelstyle = "block w-full font-bold text-lg"
  const inputstyle = "w-full border border-gray rounded-sm px-4 py-2 leading-lg text-lg mb-4"
  const checkstyle = "h-6 w-6"

  return (
    <Page pageTitle="Volunteer" description="">
      <section className="py-24">
        <div className="flex justify-center items-center">
          <div className="w-full md:w-1/2 px-6">
            <h1 className="text-center pb-6 text-5xl">Become A Volunteer</h1>
            <form name="Volunteer" method="POST" data-netlify="true" action="/thank-you" className="bg-light_gray border border-gray px-6 py-10">
              <input type="hidden" name="form-name" value="Volunteer" />

              <label className={labelstyle}>Your Name <input className={inputstyle} type="text" name="name" /></label>

              <label className={labelstyle}>Your Email <input className={inputstyle} type="email" name="email" /></label>
              <div class="grid grid-cols-6 gap-x-6 pb-6">
                <div class="col-span-6">
                  <label for="street_address" className={labelstyle}>Street address</label>
                  <input type="text" name="street_address" id="street_address" autocomplete="street-address" class={inputstyle} />
                </div>

                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label for="city" className={labelstyle}>City</label>
                  <input type="text" name="city" id="city" class={inputstyle} />
                </div>

                <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label for="state" className={labelstyle}>State / Province</label>
                  <input type="text" name="state" id="state" class={inputstyle} />
                </div>

                <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label for="postal_code" className={labelstyle}>ZIP / Postal</label>
                  <input type="text" name="postal_code" id="postal_code" autocomplete="postal-code" class={inputstyle} />
                </div>
              </div>
              <div class="block mb-6">
                <span className={labelstyle}>I Would Like To <small>(You may select multiple)</small></span>
                <div class="mt-2">
                  <div>
                    <label class="inline-flex items-center">
                      <input type="checkbox" id="supporter_list" className={checkstyle} name="Supporter List" value="Yes" />
                      <span class="ml-2">Add my name to the list of supporters</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input type="checkbox" id="yard_sign" className={checkstyle} name="Yard Sign" value="Yes" />
                      <span class="ml-2">Put a sign in my yard</span>
                    </label>
                  </div>

                  <div>
                    <label class="inline-flex items-center">
                      <input type="checkbox" id="get_out_the_vote" className={checkstyle} name="Get Out The Vote" value="Yes" />
                      <span class="ml-2">Get out the vote</span>
                    </label>
                  </div>
                  <div>
                    <label class="inline-flex items-center">
                      <input type="checkbox" id="host_fundraiser" className={checkstyle} name="Fundraiser" value="Yes" />
                      <span class="ml-2">Host a fundraiser</span>
                    </label>
                  </div>
                </div>
              </div>

              <label className={labelstyle}>Anything We Should Know? <textarea className={inputstyle} name="message"></textarea></label>

              <button type="submit" className="btn w-full">Submit &rarr;</button>
            </form>

          </div>
        </div>
      </section >
    </Page >
  );
}

export default Volunteer;