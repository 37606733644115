import React from "react";
import Layout from "../containers/layout"
import SEO from "../components/seo"

const Page = ({ children, pageTitle, description }) => {
  return (
    <Layout>
      <SEO title={pageTitle} description={description} />
      <div className="page">
        <section className="intro-short bg-court bg-cover py-36">
        </section>
        {children}
      </div>
    </Layout>
  );
}

export default Page;